import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="UI Controls"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Icons"
        tierThree="UI Controls"
      />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          UI Controls are meant to be standard symbols for manipulating an
          interface element. Keep these in your back pocket whenever layering or
          progressively disclosing within a user interface.
        </Paragraph>
        <Paragraph>UI Control icons are set to small size by default. Certain UI Control
          icons may have different sizing which is indicated in the icon name. These icons
          cannot be scaled to sizes not listed in their name.</Paragraph>
        <DontDo
          dontText="use dismiss for destructive actions."
          doText="use it to enable user’s to dismiss notifications and other ui elements."
          imgFilename="icons-uicontrols"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconUiControlsDesign($title: String = "UI Controls") {
    ...iconDataDesign
  }
`;
